<template>
  <div class="sort-btn ml-1 cursor-pointer"
       @click="changeSort"
  >
    <div class="sort-btn__btn"
         v-bind:class="{
        'sort-btn__btn--up': sortParams[sortName] === '+' || (sortParams[sortName] === '' && defaultSort === 'ASC' && checkIsSortActiveDefault),
        'sort-btn__btn--down': sortParams[sortName] === '-' || (sortParams[sortName] === '' && defaultSort === 'DESC' && checkIsSortActiveDefault),
       }"
    ></div>
  </div>
</template>

<script>

export default {
  name: "SortButton",

  props: {
    sortParams: {
      type: Object,
    },
    sortName: {
      type: String,
      required: true
    },
    defaultSort: {
      type: String,
      required: null
    },
  },

  computed: {
    checkIsSortActiveDefault() {
      let flag = true
      Object.keys(this.sortParams).map(item => {
        if(this.sortParams[item] !== '') {
          flag = false
        }
      })
      return flag
    },
  },

  methods: {
    changeSort() {
      if(this.sortParams[this.sortName] === '+' || (this.sortParams[this.sortName] === '' && this.defaultSort === 'ASC')) {
        return this.$emit('changeSort', {name: this.sortName, type: '-'})
      }
      if(this.sortParams[this.sortName] === '-' || (this.sortParams[this.sortName] === '' && this.defaultSort === 'DESC')) {
        return this.$emit('changeSort', {name: this.sortName, type: '+'})
      }
      return this.$emit('changeSort', {name: this.sortName, type: '-'})
    }
  }

}

</script>

<style lang="scss" scoped>
@import "../../../../scss/colors";
@import "../../../../scss/mixins/mixins";

.sort-btn {
  width: 14px;
  height: 16px;
  display: inline-block;

  &__btn {
    position: relative;
    top: 3px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $brown;
    /*padding-left: 30px;*/
    padding-left: 25px;
    padding-right: 20px;


    @include for-1200 {
      font-size: 0 !important;
      padding-right: 10px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 1px;
      left: 0;
      display: block;
      width: 14px;
      height: 14px;
      background: url("../../../../assets/img/common/sort-svgrepo-com.svg") center/contain no-repeat;
    }

    &--up{
      &:before {
        background: url("../../../../assets/img/common/sort-up.svg") center/contain no-repeat;
      }
    }

    &--down{
      &:before {
        background: url("../../../../assets/img/common/sort-down.svg") center/contain no-repeat;
      }
    }
  }

}

</style>