import {checkValid} from "./functions/ModelsValidate";
import {Dimensions} from "./Dimensions";
import {USA_COUNTRY_ID} from "../../staticData/staticVariables";

export function Calculator() {

    this.checkValid = checkValid

    this.data = {
        senderWarehouseId: '',
        country: '',
        countryRegion: '',
        postalCode: '',
        city: '',

        Dimensions: new Dimensions(),

        payPalClient: false,
    }

    this.validation = {
        senderWarehouseId: false,
        country: false,
        countryRegion: false,
        postalCode: false,
        city: false,
    }

    this.validationTranslate = {
        senderWarehouseId: '',
        country: '',
        countryRegion: '',
        postalCode: '',
        city: '',
    }

    this.validationTxt = {
        senderWarehouseId: false,
        country: false,
        countryRegion: false,
        postalCode: false,
        city: false,
    }

    /**
     * Getters
     */
    this.getSenderWarehouseId = () => {
        return this.data.senderWarehouseId
    }
    this.getCountry = () => {
        return this.data.country
    }
    this.getCountryRegion = () => {
        return this.data.countryRegion
    }
    this.getPostalCode = () => {
        return this.data.postalCode
    }
    this.getCity = () => {
        return this.data.city
    }
    this.getPayPalClient = () => {
        return this.data.payPalClient
    }

    /**
     * Setters
     */
    this.setSenderWarehouseId = (val) => {
        this.data.senderWarehouseId = val
    }
    this.setCountry = (val) => {
        this.data.country = val
    }
    this.setCountryRegion = (val) => {
        this.data.countryRegion = val
    }
    this.setPostalCode = (val) => {
        this.data.postalCode = val
    }
    this.setCity = (val) => {
        this.data.city = val
    }
    this.setPayPalClient = (val) => {
        this.data.payPalClient = val
    }
}

/**
 * Functions
 */

Calculator.prototype.dataValidation = function() {
    let validationItems = {
        senderWarehouseId: this.getSenderWarehouseId(),
        country: this.getCountry(),
        postalCode: this.getPostalCode(),
        city: this.getCity(),
        payPalClient: this.getPayPalClient(),
    }

    let validationOptions = {
        senderWarehouseId: {type: ['empty']},
        country: {type: ['empty']},
        postalCode: {type: ['empty']},
        city: {type: ['empty']},
        payPalClient: {type: ['empty']},
    }

    if (this.getCountry().id === USA_COUNTRY_ID) {
        validationItems['countryRegion'] = this.getCountryRegion()
        validationOptions['countryRegion'] = {type: ['empty']}
    }

    let dimensionsValidation = this.data.Dimensions.dimensionsValidate()
    return (this.checkValid(validationItems, validationOptions) && dimensionsValidation)
}