<template>
  <div class="fragment">
    <div class="order-create__head-title"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['shippingCost_calculatingResult'])"></div>
      {{$t('shippingCost_calculatingResult.localization_value.value')}}
    </div>

    <template v-if="calcData.length > 0">
<!--      <div class="soft-text fsz12"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['shippingCost_consolidationOptions'])"></div>-->
<!--        {{$t('shippingCost_consolidationOptions.localization_value.value')}}-->
<!--      </div>-->

      <div class="fragment mb-5">
        <table
            class="site-table small-table "
            v-if="!isMobileFunc()"
        >
          <thead>
          <tr>
            <th>
              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['shippingCost_companies'])"></div>
                {{$t('shippingCost_companies.localization_value.value')}}
              </div>
            </th>
            <th width="100%">
              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['shippingCost_description'])"></div>
                {{$t('shippingCost_description.localization_value.value')}}
              </div>
            </th>
            <th class="white-space-line">
              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['shippingCost_shipping'])"></div>
                {{$t('shippingCost_shipping.localization_value.value')}}
                <SortButton :sortParams="sortParams" :defaultSort="'DESC'" :sortName="'shipping'" @changeSort="data => $emit('changeSort', data)"/>
              </div>
            </th>
            <th class="white-space-line">
              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['shippingCost_DeliveryDate'])"></div>
                {{$t('shippingCost_DeliveryDate.localization_value.value')}}
                <SortButton :sortParams="sortParams" :sortName="'delivery'" @changeSort="data => $emit('changeSort', data)"/>
              </div>
            </th>
            <th class="white-space-line">

            </th>
<!--            <th class="text-lg-right white-space-line">-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_fee'])"></div>-->
<!--                {{$t('shippingCost_fee.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--            <th class="text-lg-right white-space-line">-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_total'])"></div>-->
<!--                {{$t('shippingCost_total.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
          </tr>
          </thead>
          <tbody>

          <template
              v-for="(item, index) in calcData">
            <tr
                :key="index"
            >
              <td>
                <div class="table-row">
                  <div class="table-ico"
                       v-if="item.consolidation_logo_file_name"
                       v-bind:class="{
                    'table-ico--large': item.consolidation_logo_file_name === 'table-dpd-lg.svg' || item.consolidation_logo_file_name === 'table-postnl-lg.svg',
                    'table-ico--mh32' : item.consolidation_logo_file_name === 'table-postnl-lg.svg',
                  }"
                  >
                    <img :src="'/img/company-icons-group/' + item.consolidation_logo_file_name" alt="img">
                  </div>

                  <div class="plus ml-2 mr-2" v-if="item.consolidation_logo_file_name">+</div>

                  <div class="table-ico"
                       v-if="item.logo_file_name"
                       v-bind:class="{
                    'table-ico--large': item.logo_file_name === 'table-dpd-lg.svg' || item.logo_file_name === 'table-postnl-lg.svg',
                    'table-ico--mh32' : item.logo_file_name === 'table-postnl-lg.svg',
                  }"
                  >
                    <img :src="'/img/company-icons-group/' + item.logo_file_name" alt="img">
                  </div>

                  <!--                  table-ico&#45;&#45;large-->
                  <div v-else class="table-ico ">
                    <img :src="item.logo_base64" alt="img">
                  </div>

                </div>

              </td>
              <td>
                {{item.label}}
              </td>
              <td>
                <template v-if="item.price">
                  ${{item.price}}
                </template>
                <template v-else>
                  -
                </template>
              </td>
              <td>
                <ValueHelper
                    :value="item['delivery_date']"
                    :date="true"
                    :dateType="'DD MMM, YYYY'"
                />
              </td>
              <td>
                <a :href="item.create_url">
                  <LinkButton
                      :value="''"
                      :type="'parcel'"
                  />
                </a>
              </td>
            </tr>
          </template>

          </tbody>
        </table>

        <div class="site-table-mobile"
             v-if="isMobileFunc()"
        >
          <div class="site-table-mobile__inner custom-row">
            <template v-for="(item, index) in calcData">
              <div
                  class="custom-col custom-col--50 custom-col--sm-100"
                  :key="index"
              >
                <div class="site-table-mobile__item">
                  <div class="site-table-mobile__head">
                    <div class="table-row">
                      <div class="table-ico"
                           v-if="item.consolidation_logo_file_name"
                           v-bind:class="{
                            'table-ico--large': item.consolidation_logo_file_name === 'table-dpd-lg.svg' || item.consolidation_logo_file_name === 'table-postnl-lg.svg',
                            'table-ico--mh32' : item.consolidation_logo_file_name === 'table-postnl-lg.svg',
                          }"
                      >
                        <img :src="'/img/company-icons-group/' + item.consolidation_logo_file_name" alt="img">
                      </div>

                      <div class="plus ml-2 mr-2" v-if="item.consolidation_logo_file_name">+</div>

                      <div class="table-ico"
                           v-if="item.logo_file_name"
                           v-bind:class="{
                      'table-ico--large': item.logo_file_name === 'table-dpd-lg.svg' || item.logo_file_name === 'table-postnl-lg.svg',
                      'table-ico--mh32' : item.logo_file_name === 'table-postnl-lg.svg',
                      }"
                      >
                        <img :src="'/img/company-icons-group/' + item.logo_file_name" alt="img">
                      </div>

                      <div v-else-if="item.logo_base64" class="table-ico table-ico--large">
                        <img :src="item.logo_base64" alt="img">
                      </div>

                    </div>

                  </div>
                  <div class="site-table-mobile__row custom-row">
                    <div class="site-table-mobile__piece custom-col">
                      {{item.label}}
                    </div>
                    <div class="site-table-mobile__piece custom-col">
                      {{$t('shippingCost_shipping.localization_value.value')}}
                      <span>
                         <template v-if="item.price">
                            ${{item.price}}
                          </template>
                          <template v-else>
                            -
                          </template>
                      </span>
                    </div>
                  </div>
                  <div class="site-table-mobile__row custom-row">
                    <div class="site-table-mobile__piece custom-col">
                      {{$t('shippingCost_DeliveryDate.localization_value.value')}}
                      <span>
                        <ValueHelper
                            :value="item['delivery_date']"
                            :date="true"
                            :dateType="'DD MMM, YYYY'"
                        />
                      </span>
                    </div>
                  </div>
                  <div class="site-table-mobile__row custom-row">
                    <div class="site-table-mobile__piece custom-col">
                      <a :href="item.create_url">
                        <LinkButton
                            :value="''"
                            :type="'parcel'"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div style="color: #bbad9c; font-size: 11px;">
        {{$t('calculator_calculatorCaption.localization_value.value')}}
      </div>

    </template>

<!--    <template v-if="calcData.length > 0 && calcDataHasExpress">-->
<!--      <div class="soft-text fsz12"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['shippingCost_expressOptions'])"></div>-->
<!--        {{$t('shippingCost_expressOptions.localization_value.value')}}-->
<!--      </div>-->

<!--      <div class="fragment mb-5">-->
<!--        <table-->
<!--            class="site-table small-table "-->
<!--            v-if="!isMobileFunc()"-->
<!--        >-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th>-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_companies'])"></div>-->
<!--                {{$t('shippingCost_companies.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--            <th width="100%">-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_description'])"></div>-->
<!--                {{$t('shippingCost_description.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--            <th class="white-space-line">-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_shipping'])"></div>-->
<!--                {{$t('shippingCost_shipping.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--            <th class="white-space-line">-->

<!--            </th>-->
<!--&lt;!&ndash;            <th class="text-lg-right white-space-line">&ndash;&gt;-->
<!--&lt;!&ndash;              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="admin-edit" @click="editTranslate(['shippingCost_fee'])"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                {{$t('shippingCost_fee.localization_value.value')}}&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </th>&ndash;&gt;-->
<!--&lt;!&ndash;            <th class="text-lg-right white-space-line">&ndash;&gt;-->
<!--&lt;!&ndash;              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="admin-edit" @click="editTranslate(['shippingCost_total'])"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                {{$t('shippingCost_total.localization_value.value')}}&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </th>&ndash;&gt;-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->

<!--          <template v-for="(item, index) in calcData">-->
<!--            <tr-->
<!--                v-if="item.order_type === 'express'"-->
<!--                :key="index"-->
<!--            >-->
<!--              <td>-->
<!--                <div class="table-row" v-if="item.logo_file_name">-->
<!--                  <div class="table-ico"-->
<!--                       v-bind:class="{-->
<!--                    'table-ico&#45;&#45;large': item.logo_file_name === 'table-dpd-lg.svg' || item.logo_file_name === 'table-postnl-lg.svg',-->
<!--                    'table-ico&#45;&#45;mh32' : item.logo_file_name === 'table-postnl-lg.svg',-->
<!--                  }"-->
<!--                  >-->
<!--                    <img :src="'/img/company-icons-group/' + item.logo_file_name" alt="img">-->
<!--                  </div>-->

<!--                </div>-->

<!--                &lt;!&ndash;                  table-ico&#45;&#45;large&ndash;&gt;-->
<!--                <div v-else class="table-ico ">-->
<!--                  <img :src="item.logo_base64" alt="img">-->
<!--                </div>-->

<!--              </td>-->
<!--              <td>-->
<!--                {{item.label}}-->
<!--              </td>-->
<!--              <td>-->
<!--                <template v-if="item.price">-->
<!--                  ${{item.price}}-->
<!--                </template>-->
<!--                <template v-else>-->
<!--                  - -->
<!--                </template>-->
<!--              </td>-->
<!--              <td>-->
<!--                <a :href="item.create_url">-->
<!--                  <LinkButton-->
<!--                      :value="$t('common_create.localization_value.value')"-->
<!--                      :type="'dog'"-->
<!--                  />-->
<!--                </a>-->
<!--              </td>-->
<!--            </tr>-->
<!--          </template>-->

<!--          </tbody>-->
<!--        </table>-->

<!--        <div class="site-table-mobile"-->
<!--             v-if="isMobileFunc()"-->
<!--        >-->
<!--          <div class="site-table-mobile__inner custom-row">-->
<!--            <template-->
<!--                v-for="(item, index) in calcData">-->
<!--              <div-->
<!--                  class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sm-100"-->
<!--                  v-if="item.order_type === 'express'"-->
<!--                  :key="index"-->
<!--              >-->
<!--                <div class="site-table-mobile__item">-->
<!--                  <div class="site-table-mobile__head">-->
<!--                    <div class="table-row" v-if="item.logo_file_name">-->
<!--                      <div class="table-ico"-->
<!--                           v-bind:class="{-->
<!--                      'table-ico&#45;&#45;large': item.logo_file_name === 'table-dpd-lg.svg' || item.logo_file_name === 'table-postnl-lg.svg',-->
<!--                      'table-ico&#45;&#45;mh32' : item.logo_file_name === 'table-postnl-lg.svg',-->
<!--                      }"-->
<!--                      >-->
<!--                        <img :src="'/img/company-icons-group/' + item.logo_file_name" alt="img">-->
<!--                      </div>-->
<!--                    </div>-->

<!--                    <div v-else class="table-ico table-ico&#45;&#45;large">-->
<!--                      <img :src="item.data.logo_base64" alt="img">-->
<!--                    </div>-->

<!--                  </div>-->
<!--                  <div class="site-table-mobile__row custom-row">-->
<!--                    <div class="site-table-mobile__piece custom-col">-->
<!--                      {{item.label}}-->
<!--                    </div>-->
<!--                    <div class="site-table-mobile__piece custom-col custom-col&#45;&#45;50">-->
<!--                      {{$t('shippingCost_shipping.localization_value.value')}}-->
<!--                      <span>-->
<!--                         <template v-if="item.price">-->
<!--                            ${{item.price}}-->
<!--                          </template>-->
<!--                          <template v-else>-->
<!--                            - -->
<!--                          </template>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="site-table-mobile__row custom-row">-->
<!--                    <div class="site-table-mobile__piece custom-col">-->
<!--                      <a :href="item.create_url">-->
<!--                        <LinkButton-->
<!--                            :value="$t('common_create.localization_value.value')"-->
<!--                            :type="'dog'"-->
<!--                        />-->
<!--                      </a>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </template>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </template>-->



<!--    <template v-if="getConsolidationTableItems.length && !fromPoland">-->
<!--      <div class="soft-text fsz12"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['shippingCost_consolidationOptions'])"></div>-->
<!--        {{$t('shippingCost_consolidationOptions.localization_value.value')}}-->
<!--      </div>-->

<!--      <div class="fragment mb-5">-->
<!--        <table-->
<!--            class="site-table small-table "-->
<!--            v-if="!isMobileFunc()"-->
<!--        >-->
<!--          <thead>-->
<!--            <tr>-->
<!--              <th>-->
<!--                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                  <div class="admin-edit" @click="editTranslate(['shippingCost_companies'])"></div>-->
<!--                  {{$t('shippingCost_companies.localization_value.value')}}-->
<!--                </div>-->
<!--              </th>-->
<!--              <th width="100%">-->
<!--                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                  <div class="admin-edit" @click="editTranslate(['shippingCost_description'])"></div>-->
<!--                  {{$t('shippingCost_description.localization_value.value')}}-->
<!--                </div>-->
<!--              </th>-->
<!--              <th class="white-space-line">-->
<!--                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                  <div class="admin-edit" @click="editTranslate(['shippingCost_shipping'])"></div>-->
<!--                  {{$t('shippingCost_shipping.localization_value.value')}}-->
<!--                </div>-->
<!--              </th>-->
<!--              <th class="text-lg-right white-space-line">-->
<!--                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                  <div class="admin-edit" @click="editTranslate(['shippingCost_fee'])"></div>-->
<!--                  {{$t('shippingCost_fee.localization_value.value')}}-->
<!--                </div>-->
<!--              </th>-->
<!--              <th class="text-lg-right white-space-line">-->
<!--                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                  <div class="admin-edit" @click="editTranslate(['shippingCost_total'])"></div>-->
<!--                  {{$t('shippingCost_total.localization_value.value')}}-->
<!--                </div>-->
<!--              </th>-->
<!--            </tr>-->
<!--          </thead>-->
<!--          <tbody>-->

<!--            <tr-->
<!--                v-for="(item, index) in getConsolidationTableItems"-->
<!--                :key="index"-->
<!--            >-->
<!--              <td>-->
<!--                <div class="table-row" v-if="item.type === DHL_DELIVERY_SERVICE.name ||-->
<!--                        item.type === TNT_DELIVERY_SERVICE.name ||-->
<!--                        item.type === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                        item.type === DPD_DELIVERY_SERVICE.name ||-->
<!--                        item.data.shipping_company.toLowerCase() === USPS_DELIVERY_SERVICE.name ||-->
<!--                        item.data.delivery_method_id === APC_DELIVERY_SERVICE.deliveryMethodId ||-->
<!--                        item.type === POSTNL_DELIVERY_SERVICE.label">-->
<!--                  <div class="table-ico"-->
<!--                       v-if="!isGlobal24(item)"-->
<!--                       v-bind:class="{-->
<!--                    'table-ico&#45;&#45;large': item.type === DPD_DELIVERY_SERVICE.name || item.type === POSTNL_DELIVERY_SERVICE.label,-->
<!--                    'table-ico&#45;&#45;mh32' : item.type === POSTNL_DELIVERY_SERVICE.label-->
<!--                  }"-->
<!--                  >-->

<!--                    <img v-if="item.type === DHL_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-dhl-lg.svg" alt="img">-->
<!--                    <img v-if="item.type === TNT_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-tnt-lg.svg" alt="img">-->
<!--                    <img v-if="item.type === FEDEX_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-fedex-lg.svg" alt="img">-->
<!--                    <img v-if="item.type === DPD_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-dpd-lg.svg" alt="img">-->
<!--                    <img v-if="item.type === USPS_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-usps-lg.svg" alt="img">-->
<!--                    <img v-if="item.type === APC_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-apc-lg.svg" alt="img">-->
<!--                    <img v-if="item.type === POSTNL_DELIVERY_SERVICE.label" src="/img/company-icons-group/table-postnl-lg.svg" alt="img">-->

<!--                  </div>-->

<!--                  <div v-if="(item.type === DHL_DELIVERY_SERVICE.name ||-->
<!--                        item.type === TNT_DELIVERY_SERVICE.name ||-->
<!--                        item.type === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                        item.type === USPS_DELIVERY_SERVICE.name ||-->
<!--                        item.data.delivery_method_id === APC_DELIVERY_SERVICE.deliveryMethodId) &&-->
<!--                        !isGlobal24(item)"-->
<!--                       class="plus ml-2 mr-2">-->
<!--                    +-->
<!--                  </div>-->

<!--                  <div v-if="item.type !== DPD_DELIVERY_SERVICE.name && item.type !== POSTNL_DELIVERY_SERVICE.label" class="table-ico">-->

<!--                    <img v-if="item.data.shipping_company.toLowerCase() === DHL_DELIVERY_SERVICE.name ||-->
<!--                                item.data.shipping_company.toLowerCase() === DHLEXPRESS_DELIVERY_SERVICE.name"-->
<!--                         src="/img/company-icons-group/table-dhl-lg.svg" alt="img">-->

<!--                    <img v-if="item.data.shipping_company.toLowerCase() === TNT_DELIVERY_SERVICE.name"-->
<!--                         src="/img/company-icons-group/table-tnt-lg.svg" alt="img">-->

<!--                    <img v-if="item.data.shipping_company.toLowerCase() === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                                item.data.shipping_company.toLowerCase() === FEDEXSMARTPOST_DELIVERY_SERVICE.name"-->
<!--                         src="/img/company-icons-group/table-fedex-lg.svg" alt="img">-->

<!--                    <img v-if="item.data.shipping_company.toLowerCase() === DPD_DELIVERY_SERVICE.name && item.type !== DPD_DELIVERY_SERVICE.name"-->
<!--                         src="/img/company-icons-group/table-dpd-lg.svg" alt="img">-->

<!--                    <img v-if="item.data.shipping_company.toLowerCase() === USPS_DELIVERY_SERVICE.name"-->
<!--                         src="/img/company-icons-group/table-usps-lg.svg" alt="img">-->

<!--                    <img v-if="item.data.delivery_method_id === APC_DELIVERY_SERVICE.deliveryMethodId"-->
<!--                         src="/img/company-icons-group/table-apc-lg.svg" alt="img">-->

<!--                    <img v-if="item.data.shipping_company.toLowerCase() === POSTNL_DELIVERY_SERVICE.name"-->
<!--                         src="/img/company-icons-group/table-postnl-lg.svg" alt="img">-->

<!--                  </div>-->

<!--                </div>-->

<!--                &lt;!&ndash;                  table-ico&#45;&#45;large&ndash;&gt;-->
<!--                <div v-else class="table-ico ">-->
<!--                  <img :src="item.data.logo_base64" alt="img">-->
<!--                </div>-->

<!--              </td>-->
<!--              <td>-->
<!--                <template v-if="item.type === DHL_DELIVERY_SERVICE.name">DHL Consolidation</template>-->
<!--                <template v-if="item.type === TNT_DELIVERY_SERVICE.name">TNT Consolidation</template>-->
<!--                <template v-if="item.type === FEDEX_DELIVERY_SERVICE.name">FedEx Consolidation</template>-->
<!--                <template v-if="item.type === ROYAL_MAIL_DELIVERY_SERVICE.name">{{item.data.shipping_method}}</template>-->

<!--                <template v-if="item.type === DPD_DELIVERY_SERVICE.name">-->
<!--                  <template v-if="item.data.title !== ''">{{item.data.title}}</template>-->
<!--                  <template v-else>DPD Consolidation</template>-->
<!--                </template>-->

<!--                <template v-if="item.type === POSTNL_DELIVERY_SERVICE.label">-->
<!--                  <template v-if="item.data.title !== ''">{{item.data.title}}</template>-->
<!--                  <template v-else>PostNL Consolidation</template>-->
<!--                </template>-->

<!--                <template v-if="item.type !== DPD_DELIVERY_SERVICE.name && item.type !== POSTNL_DELIVERY_SERVICE.label">-->
<!--                    <span class="ml-1" v-if="(item.type === DHL_DELIVERY_SERVICE.name ||-->
<!--                            item.type === TNT_DELIVERY_SERVICE.name ||-->
<!--                            item.type === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                            item.type === USPS_DELIVERY_SERVICE.name ||-->
<!--                            item.data.delivery_method_id === APC_DELIVERY_SERVICE.deliveryMethodId) && !isGlobal24(item)">+</span>-->
<!--                  {{item.data.name}}-->
<!--                </template>-->

<!--                <template v-else-if="item.data.message">-->
<!--                  <br>-->
<!--                  <div style="white-space: normal;" class="mt-1"-->
<!--                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                    <div class="admin-edit" @click="editTranslate(['shippingCost_dpdCalcText'])"></div>-->
<!--                    {{$t('shippingCost_dpdCalcText.localization_value.value')}}-->
<!--                  </div>-->
<!--                </template>-->
<!--              </td>-->
<!--              <td>-->
<!--                <template-->
<!--                    v-if="item.data.price"-->
<!--                >-->
<!--                  ${{item.data.price}}-->
<!--                </template>-->
<!--                <template v-else>-->
<!--                  - -->
<!--                </template>-->
<!--              </td>-->
<!--              <td align="right">-->
<!--                <template-->
<!--                    v-if="item.data.fee"-->
<!--                >-->
<!--                  ${{item.data.fee}}-->
<!--                </template>-->
<!--                <template v-else>-->
<!--                  - -->
<!--                </template>-->
<!--              </td>-->
<!--              <td align="right">-->
<!--                <div class="table-row">-->
<!--                  <template>-->
<!--                    ${{calcTotal(item)}}-->
<!--                  </template>-->
<!--                  &lt;!&ndash;                  <template v-else>&ndash;&gt;-->
<!--                  &lt;!&ndash;                    <template&ndash;&gt;-->
<!--                  &lt;!&ndash;                        v-if="item.data.price"&ndash;&gt;-->
<!--                  &lt;!&ndash;                    >&ndash;&gt;-->
<!--                  &lt;!&ndash;                      ${{item.data.price}}&ndash;&gt;-->
<!--                  &lt;!&ndash;                    </template>&ndash;&gt;-->
<!--                  &lt;!&ndash;                    <template v-else>&ndash;&gt;-->
<!--                  &lt;!&ndash;                      - &ndash;&gt;-->
<!--                  &lt;!&ndash;                    </template>&ndash;&gt;-->
<!--                  &lt;!&ndash;                  </template>&ndash;&gt;-->
<!--                </div>-->
<!--              </td>-->
<!--            </tr>-->

<!--          </tbody>-->
<!--        </table>-->

<!--        <div class="site-table-mobile"-->
<!--             v-if="isMobileFunc()"-->
<!--        >-->
<!--          <div class="site-table-mobile__inner custom-row">-->
<!--            <div-->
<!--                class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sm-100"-->
<!--                v-for="(item, index) in getConsolidationTableItems"-->
<!--                :key="index"-->
<!--            >-->
<!--              <div class="site-table-mobile__item">-->
<!--                <div class="site-table-mobile__head">-->
<!--                  <div class="table-row" v-if="item.type === DHL_DELIVERY_SERVICE.name ||-->
<!--                          item.type === TNT_DELIVERY_SERVICE.name ||-->
<!--                          item.type === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                          item.type === DPD_DELIVERY_SERVICE.name ||-->
<!--                          item.data.shipping_company.toLowerCase() === USPS_DELIVERY_SERVICE.name ||-->
<!--                          item.data.delivery_method_id === APC_DELIVERY_SERVICE.deliveryMethodId ||-->
<!--                          item.type === POSTNL_DELIVERY_SERVICE.label">-->
<!--                    <div class="table-ico"-->
<!--                         v-if="!isGlobal24(item)"-->
<!--                         v-bind:class="{-->
<!--                      'table-ico&#45;&#45;large': item.type === DPD_DELIVERY_SERVICE.name || item.type === POSTNL_DELIVERY_SERVICE.label,-->
<!--                      'table-ico&#45;&#45;mh32' : item.type === POSTNL_DELIVERY_SERVICE.label-->
<!--                    }"-->
<!--                    >-->
<!--                      <img v-if="item.type === DHL_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-dhl-lg.svg" alt="img">-->
<!--                      <img v-if="item.type === TNT_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-tnt-lg.svg" alt="img">-->
<!--                      <img v-if="item.type === FEDEX_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-fedex-lg.svg" alt="img">-->
<!--                      <img v-if="item.type === DPD_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-dpd-lg.svg" alt="img">-->
<!--                      <img v-if="item.type === USPS_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-usps-lg.svg" alt="img">-->
<!--                      <img v-if="item.type === APC_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-apc-lg.svg" alt="img">-->
<!--                      <img v-if="item.type === POSTNL_DELIVERY_SERVICE.label" src="/img/company-icons-group/table-postnl-lg.svg" alt="img">-->
<!--                    </div>-->
<!--                    <div v-if="(item.type === DHL_DELIVERY_SERVICE.name ||-->
<!--                          item.type === TNT_DELIVERY_SERVICE.name ||-->
<!--                          item.type === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                          item.type === USPS_DELIVERY_SERVICE.name ||-->
<!--                          item.data.delivery_method_id === APC_DELIVERY_SERVICE.deliveryMethodId) && !isGlobal24(item)"-->
<!--                         class="plus ml-2 mr-2">-->
<!--                      +-->
<!--                    </div>-->
<!--                    <div v-if="item.type !== DPD_DELIVERY_SERVICE.name && item.type !== POSTNL_DELIVERY_SERVICE.label" class="table-ico">-->

<!--                      <img v-if="item.data.shipping_company.toLowerCase() === DHL_DELIVERY_SERVICE.name ||-->
<!--                              item.data.shipping_company.toLowerCase() === DHLEXPRESS_DELIVERY_SERVICE.name"-->
<!--                           src="/img/company-icons-group/table-dhl-lg.svg" alt="img">-->

<!--                      <img v-if="item.data.shipping_company.toLowerCase() === TNT_DELIVERY_SERVICE.name"-->
<!--                           src="/img/company-icons-group/table-tnt-lg.svg" alt="img">-->

<!--                      <img v-if="item.data.shipping_company.toLowerCase() === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                              item.data.shipping_company.toLowerCase() === FEDEXSMARTPOST_DELIVERY_SERVICE.name"-->
<!--                           src="/img/company-icons-group/table-fedex-lg.svg" alt="img">-->

<!--                      <img v-if="item.data.shipping_company.toLowerCase() === DPD_DELIVERY_SERVICE.name && item.type !== DPD_DELIVERY_SERVICE.name"-->
<!--                           src="/img/company-icons-group/table-dpd-lg.svg" alt="img">-->

<!--                      <img v-if="item.data.shipping_company.toLowerCase() === USPS_DELIVERY_SERVICE.name"-->
<!--                           src="/img/company-icons-group/table-usps-lg.svg" alt="img">-->

<!--                      <img v-if="item.data.delivery_method_id === APC_DELIVERY_SERVICE.deliveryMethodId"-->
<!--                           src="/img/company-icons-group/table-apc-lg.svg" alt="img">-->

<!--                      <img v-if="item.data.shipping_company.toLowerCase() === POSTNL_DELIVERY_SERVICE.name"-->
<!--                           src="/img/company-icons-group/table-postnl-lg.svg" alt="img">-->

<!--                    </div>-->
<!--                  </div>-->

<!--                  <div v-else class="table-ico table-ico&#45;&#45;large">-->
<!--                    <img :src="item.data.logo_base64" alt="img">-->
<!--                  </div>-->

<!--                </div>-->
<!--                <div class="site-table-mobile__row custom-row">-->
<!--                  <div class="site-table-mobile__piece custom-col">-->

<!--                    <template v-if="item.type === DHL_DELIVERY_SERVICE.name">DHL Consolidation</template>-->
<!--                    <template v-if="item.type === TNT_DELIVERY_SERVICE.name">TNT Consolidation</template>-->
<!--                    <template v-if="item.type === FEDEX_DELIVERY_SERVICE.name">FedEx Consolidation</template>-->
<!--                    <template v-if="item.type === ROYAL_MAIL_DELIVERY_SERVICE.name">{{item.data.shipping_method}}</template>-->

<!--                    <template v-if="item.type === DPD_DELIVERY_SERVICE.name">-->
<!--                      <template v-if="item.data.title !== ''">{{item.data.title}}</template>-->
<!--                      <template v-else>DPD Consolidation</template>-->
<!--                    </template>-->

<!--                    <template v-if="item.type === POSTNL_DELIVERY_SERVICE.label">-->
<!--                      <template v-if="item.data.title !== ''">{{item.data.title}}</template>-->
<!--                      <template v-else>PostNL Consolidation</template>-->
<!--                    </template>-->

<!--                    <template v-if="item.type !== DPD_DELIVERY_SERVICE.name && item.type !== POSTNL_DELIVERY_SERVICE.label">-->
<!--                      <span class="ml-1" v-if="(item.type === DHL_DELIVERY_SERVICE.name ||-->
<!--                          item.type === TNT_DELIVERY_SERVICE.name ||-->
<!--                          item.type === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                          item.type === USPS_DELIVERY_SERVICE.name ||-->
<!--                          item.data.delivery_method_id === APC_DELIVERY_SERVICE.deliveryMethodId) && !isGlobal24(item)">+</span>-->
<!--                      {{item.data.name}}-->
<!--                    </template>-->

<!--                    <template v-else-if="item.data.message">-->
<!--                      <br>-->
<!--                      <div style="white-space: normal;" class="mt-1"-->
<!--                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                        <div class="admin-edit" @click="editTranslate(['shippingCost_dpdCalcText'])"></div>-->
<!--                        {{$t('shippingCost_dpdCalcText.localization_value.value')}}-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </div>-->
<!--                  <div class="site-table-mobile__piece custom-col custom-col&#45;&#45;50">-->
<!--                    {{$t('shippingCost_shipping.localization_value.value')}}-->
<!--                    <span>-->
<!--                     <template-->
<!--                         v-if="item.data.price"-->
<!--                     >-->
<!--                        ${{item.data.price}}-->
<!--                      </template>-->
<!--                      <template v-else>-->
<!--                        - -->
<!--                      </template>-->
<!--                  </span>-->
<!--                  </div>-->
<!--                  <div class="site-table-mobile__piece custom-col custom-col&#45;&#45;50">-->
<!--                    {{$t('shippingCost_fee.localization_value.value')}}-->
<!--                    <span>-->
<!--                   <template-->
<!--                       v-if="item.data.fee"-->
<!--                   >-->
<!--                      ${{item.data.fee}}-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                      - -->
<!--                    </template>-->
<!--                  </span>-->
<!--                  </div>-->
<!--                  <div class="site-table-mobile__piece custom-col">-->
<!--                    {{$t('shippingCost_total.localization_value.value')}}-->

<!--                    <span>-->
<!--                      <template>-->
<!--                        ${{calcTotal(item)}}-->
<!--                      </template>-->
<!--&lt;!&ndash;                      <template v-else>&ndash;&gt;-->
<!--&lt;!&ndash;                        <template&ndash;&gt;-->
<!--&lt;!&ndash;                            v-if="item.data.price"&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                        ${{item.data.price}}&ndash;&gt;-->
<!--&lt;!&ndash;                        </template>&ndash;&gt;-->
<!--&lt;!&ndash;                        <template v-else>&ndash;&gt;-->
<!--&lt;!&ndash;                          - &ndash;&gt;-->
<!--&lt;!&ndash;                        </template>&ndash;&gt;-->
<!--&lt;!&ndash;                      </template>&ndash;&gt;-->
<!--                 </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </template>-->

<!--    <template v-if="getGlobalOrderTableItems.length">-->
<!--      <div class="soft-text fsz12"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['shippingCost_consolidationOptions'])"></div>-->
<!--        {{$t('shippingCost_globalOrderOptions.localization_value.value')}}-->
<!--      </div>-->

<!--      <div class="fragment mb-5">-->
<!--        <table-->
<!--            class="site-table small-table "-->
<!--            v-if="!isMobileFunc()"-->
<!--        >-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th>-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_companies'])"></div>-->
<!--                {{$t('shippingCost_companies.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--            <th width="100%">-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_description'])"></div>-->
<!--                {{$t('shippingCost_description.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--            <th class="white-space-line">-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_shipping'])"></div>-->
<!--                {{$t('shippingCost_shipping.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--            <th class="text-lg-right white-space-line">-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_fee'])"></div>-->
<!--                {{$t('shippingCost_fee.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--            <th class="text-lg-right white-space-line">-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_total'])"></div>-->
<!--                {{$t('shippingCost_total.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->

<!--          <tr-->
<!--              v-for="(item, index) in getGlobalOrderTableItems"-->
<!--              :key="index"-->
<!--          >-->
<!--            <td>-->
<!--              <div class="table-row" v-if="item.type === DHL_DELIVERY_SERVICE.name ||-->
<!--                          item.type === TNT_DELIVERY_SERVICE.name ||-->
<!--                          item.type === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                          item.type === DPD_DELIVERY_SERVICE.name ||-->
<!--                          item.type === USPS_DELIVERY_SERVICE.name ||-->
<!--                          item.data.delivery_method_id === APC_DELIVERY_SERVICE.deliveryMethodId ||-->
<!--                          item.type === POSTNL_DELIVERY_SERVICE.label">-->
<!--                <div class="table-ico"-->
<!--                     v-bind:class="{-->
<!--                      'table-ico&#45;&#45;large': item.type === DPD_DELIVERY_SERVICE.name || item.type === POSTNL_DELIVERY_SERVICE.label,-->
<!--                      'table-ico&#45;&#45;mh32' : item.type === POSTNL_DELIVERY_SERVICE.label-->
<!--                    }"-->
<!--                >-->

<!--                  <img v-if="item.type === DHL_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-dhl-lg.svg" alt="img">-->
<!--                  <img v-if="item.type === TNT_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-tnt-lg.svg" alt="img">-->
<!--                  <img v-if="item.type === FEDEX_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-fedex-lg.svg" alt="img">-->
<!--                  <img v-if="item.type === DPD_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-dpd-lg.svg" alt="img">-->
<!--                  <img v-if="item.type === USPS_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-usps-lg.svg" alt="img">-->
<!--                  <img v-if="item.type === APC_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-apc-lg.svg" alt="img">-->
<!--                  <img v-if="item.type === POSTNL_DELIVERY_SERVICE.label" src="/img/company-icons-group/table-postnl-lg.svg" alt="img">-->

<!--                </div>-->

<!--                <div v-if="item.type === DHL_DELIVERY_SERVICE.name ||-->
<!--                          item.type === TNT_DELIVERY_SERVICE.name ||-->
<!--                          item.type === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                          item.type === USPS_DELIVERY_SERVICE.name ||-->
<!--                          item.data.delivery_method_id === APC_DELIVERY_SERVICE.deliveryMethodId"-->
<!--                     class="plus ml-2 mr-2">-->
<!--                  +-->
<!--                </div>-->

<!--                <div v-if="item.type !== DPD_DELIVERY_SERVICE.name && item.type !== POSTNL_DELIVERY_SERVICE.label" class="table-ico">-->

<!--                  <img v-if="item.data.shipping_company.toLowerCase() === DHL_DELIVERY_SERVICE.name ||-->
<!--                              item.data.shipping_company.toLowerCase() === DHLEXPRESS_DELIVERY_SERVICE.name"-->
<!--                       src="/img/company-icons-group/table-dhl-lg.svg" alt="img">-->

<!--                  <img v-if="item.data.shipping_company.toLowerCase() === TNT_DELIVERY_SERVICE.name"-->
<!--                       src="/img/company-icons-group/table-tnt-lg.svg" alt="img">-->

<!--                  <img v-if="item.data.shipping_company.toLowerCase() === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                              item.data.shipping_company.toLowerCase() === FEDEXSMARTPOST_DELIVERY_SERVICE.name"-->
<!--                       src="/img/company-icons-group/table-fedex-lg.svg" alt="img">-->

<!--                  <img v-if="item.data.shipping_company.toLowerCase() === DPD_DELIVERY_SERVICE.name && item.type !== DPD_DELIVERY_SERVICE.name"-->
<!--                       src="/img/company-icons-group/table-dpd-lg.svg" alt="img">-->

<!--                  <img v-if="item.data.shipping_company.toLowerCase() === USPS_DELIVERY_SERVICE.name"-->
<!--                       src="/img/company-icons-group/table-usps-lg.svg" alt="img">-->

<!--                  <img v-if="item.data.delivery_method_id === APC_DELIVERY_SERVICE.deliveryMethodId"-->
<!--                       src="/img/company-icons-group/table-apc-lg.svg" alt="img">-->

<!--                  <img v-if="item.data.shipping_company.toLowerCase() === POSTNL_DELIVERY_SERVICE.name"-->
<!--                       src="/img/company-icons-group/table-postnl-lg.svg" alt="img">-->

<!--                </div>-->

<!--              </div>-->

<!--              &lt;!&ndash;                  table-ico&#45;&#45;large&ndash;&gt;-->
<!--              <div v-else class="table-ico ">-->
<!--                <img :src="item.data.logo_base64" alt="img">-->
<!--              </div>-->

<!--            </td>-->
<!--            <td>-->
<!--              <template v-if="item.type === DHL_DELIVERY_SERVICE.name">DHL Consolidation</template>-->
<!--              <template v-if="item.type === TNT_DELIVERY_SERVICE.name">TNT Consolidation</template>-->
<!--              <template v-if="item.type === FEDEX_DELIVERY_SERVICE.name">FedEx Consolidation</template>-->
<!--              <template v-if="item.type === ROYAL_MAIL_DELIVERY_SERVICE.name">{{item.data.shipping_method}}</template>-->

<!--              <template v-if="item.type === DPD_DELIVERY_SERVICE.name">-->
<!--                <template v-if="item.data.title !== ''">{{item.data.title}}</template>-->
<!--                <template v-else>DPD Consolidation</template>-->
<!--              </template>-->

<!--              <template v-if="item.type === POSTNL_DELIVERY_SERVICE.label">-->
<!--                <template v-if="item.data.title !== ''">{{item.data.title}}</template>-->
<!--                <template v-else>PostNL Consolidation</template>-->
<!--              </template>-->

<!--              <template v-if="item.type !== DPD_DELIVERY_SERVICE.name && item.type !== POSTNL_DELIVERY_SERVICE.label">-->
<!--                  <span class="ml-1" v-if="item.type === DHL_DELIVERY_SERVICE.name ||-->
<!--                          item.type === TNT_DELIVERY_SERVICE.name ||-->
<!--                          item.type === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                          item.type === USPS_DELIVERY_SERVICE.name ||-->
<!--                          item.data.delivery_method_id === APC_DELIVERY_SERVICE.deliveryMethodId">+</span>-->
<!--                <template v-if="item.data.name === 'Post NL(paid VAT)' && (fromPoland || fromPolandToEu)">-->
<!--                  {{item.data.name.replace('(paid VAT)', '')}}-->
<!--                </template>-->
<!--                <template v-else>-->
<!--                  {{item.data.name}}-->
<!--                </template>-->
<!--              </template>-->

<!--              <template v-else-if="item.data.message">-->
<!--                <br>-->
<!--                <div style="white-space: normal;" class="mt-1"-->
<!--                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                  <div class="admin-edit" @click="editTranslate(['shippingCost_dpdCalcText'])"></div>-->
<!--                  {{$t('shippingCost_dpdCalcText.localization_value.value')}}-->
<!--                </div>-->
<!--              </template>-->
<!--            </td>-->
<!--            <td>-->
<!--              <template-->
<!--                  v-if="item.data.price"-->
<!--              >-->
<!--                ${{item.data.price}}-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                - -->
<!--              </template>-->
<!--            </td>-->
<!--            <td align="right">-->
<!--              <template-->
<!--                  v-if="item.data.fee"-->
<!--              >-->
<!--                ${{item.data.fee}}-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                - -->
<!--              </template>-->
<!--            </td>-->
<!--            <td align="right">-->
<!--              <div class="table-row">-->
<!--                <template>-->
<!--                  ${{calcTotal(item)}}-->
<!--                </template>-->
<!--              </div>-->
<!--            </td>-->
<!--          </tr>-->

<!--          </tbody>-->
<!--        </table>-->

<!--        <div class="site-table-mobile"-->
<!--             v-if="isMobileFunc()"-->
<!--        >-->
<!--          <div class="site-table-mobile__inner custom-row">-->
<!--            <div-->
<!--                class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sm-100"-->
<!--                v-for="(item, index) in getGlobalOrderTableItems"-->
<!--                :key="index"-->
<!--            >-->
<!--              <div class="site-table-mobile__item">-->
<!--                <div class="site-table-mobile__head">-->

<!--                  <div class="table-row" v-if="item.type === DHL_DELIVERY_SERVICE.name ||-->
<!--                          item.type === TNT_DELIVERY_SERVICE.name ||-->
<!--                          item.type === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                          item.type === DPD_DELIVERY_SERVICE.name ||-->
<!--                          item.type === USPS_DELIVERY_SERVICE.name ||-->
<!--                          item.data.delivery_method_id === APC_DELIVERY_SERVICE.deliveryMethodId ||-->
<!--                          item.type === POSTNL_DELIVERY_SERVICE.label">-->
<!--                    <div class="table-ico"-->
<!--                         v-bind:class="{-->
<!--                      'table-ico&#45;&#45;large': item.type === DPD_DELIVERY_SERVICE.name || item.type === POSTNL_DELIVERY_SERVICE.label,-->
<!--                      'table-ico&#45;&#45;mh32' : item.type === POSTNL_DELIVERY_SERVICE.label-->
<!--                    }"-->
<!--                    >-->

<!--                      <img v-if="item.type === DHL_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-dhl-lg.svg" alt="img">-->
<!--                      <img v-if="item.type === TNT_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-tnt-lg.svg" alt="img">-->
<!--                      <img v-if="item.type === FEDEX_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-fedex-lg.svg" alt="img">-->
<!--                      <img v-if="item.type === DPD_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-dpd-lg.svg" alt="img">-->
<!--                      <img v-if="item.type === USPS_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-usps-lg.svg" alt="img">-->
<!--                      <img v-if="item.type === APC_DELIVERY_SERVICE.name" src="/img/company-icons-group/table-apc-lg.svg" alt="img">-->
<!--                      <img v-if="item.type === POSTNL_DELIVERY_SERVICE.label" src="/img/company-icons-group/table-postnl-lg.svg" alt="img">-->

<!--                    </div>-->

<!--                    <div v-if="item.type === DHL_DELIVERY_SERVICE.name ||-->
<!--                          item.type === TNT_DELIVERY_SERVICE.name ||-->
<!--                          item.type === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                          item.type === USPS_DELIVERY_SERVICE.name ||-->
<!--                          item.data.delivery_method_id === APC_DELIVERY_SERVICE.deliveryMethodId"-->
<!--                         class="plus ml-2 mr-2">-->
<!--                      +-->
<!--                    </div>-->

<!--                    <div v-if="item.type !== DPD_DELIVERY_SERVICE.name && item.type !== POSTNL_DELIVERY_SERVICE.label" class="table-ico">-->

<!--                      <img v-if="item.data.shipping_company.toLowerCase() === DHL_DELIVERY_SERVICE.name ||-->
<!--                              item.data.shipping_company.toLowerCase() === DHLEXPRESS_DELIVERY_SERVICE.name"-->
<!--                           src="/img/company-icons-group/table-dhl-lg.svg" alt="img">-->

<!--                      <img v-if="item.data.shipping_company.toLowerCase() === TNT_DELIVERY_SERVICE.name"-->
<!--                           src="/img/company-icons-group/table-tnt-lg.svg" alt="img">-->

<!--                      <img v-if="item.data.shipping_company.toLowerCase() === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                              item.data.shipping_company.toLowerCase() === FEDEXSMARTPOST_DELIVERY_SERVICE.name"-->
<!--                           src="/img/company-icons-group/table-fedex-lg.svg" alt="img">-->

<!--                      <img v-if="item.data.shipping_company.toLowerCase() === DPD_DELIVERY_SERVICE.name && item.type !== DPD_DELIVERY_SERVICE.name"-->
<!--                           src="/img/company-icons-group/table-dpd-lg.svg" alt="img">-->

<!--                      <img v-if="item.data.shipping_company.toLowerCase() === USPS_DELIVERY_SERVICE.name"-->
<!--                           src="/img/company-icons-group/table-usps-lg.svg" alt="img">-->

<!--                      <img v-if="item.data.delivery_method_id === APC_DELIVERY_SERVICE.deliveryMethodId"-->
<!--                           src="/img/company-icons-group/table-apc-lg.svg" alt="img">-->

<!--                      <img v-if="item.data.shipping_company.toLowerCase() === POSTNL_DELIVERY_SERVICE.name"-->
<!--                           src="/img/company-icons-group/table-postnl-lg.svg" alt="img">-->

<!--                    </div>-->

<!--                  </div>-->

<!--                  &lt;!&ndash;                  table-ico&#45;&#45;large&ndash;&gt;-->
<!--                  <div v-else class="table-ico ">-->
<!--                    <img :src="item.data.logo_base64" alt="img">-->
<!--                  </div>-->

<!--                </div>-->
<!--                <div class="site-table-mobile__row custom-row">-->
<!--                  <div class="site-table-mobile__piece custom-col">-->

<!--                    <template v-if="item.type === DHL_DELIVERY_SERVICE.name">DHL Consolidation</template>-->
<!--                    <template v-if="item.type === TNT_DELIVERY_SERVICE.name">TNT Consolidation</template>-->
<!--                    <template v-if="item.type === FEDEX_DELIVERY_SERVICE.name">FedEx Consolidation</template>-->
<!--                    <template v-if="item.type === ROYAL_MAIL_DELIVERY_SERVICE.name">{{item.data.shipping_method}}</template>-->

<!--                    <template v-if="item.type === DPD_DELIVERY_SERVICE.name">-->
<!--                      <template v-if="item.data.title !== ''">{{item.data.title}}</template>-->
<!--                      <template v-else>DPD Consolidation</template>-->
<!--                    </template>-->

<!--                    <template v-if="item.type === POSTNL_DELIVERY_SERVICE.label">-->
<!--                      <template v-if="item.data.title !== ''">{{item.data.title}}</template>-->
<!--                      <template v-else>PostNL Consolidation</template>-->
<!--                    </template>-->

<!--                    <template v-if="item.type !== DPD_DELIVERY_SERVICE.name && item.type !== POSTNL_DELIVERY_SERVICE.label">-->
<!--                      <span class="ml-1" v-if="item.type === DHL_DELIVERY_SERVICE.name ||-->
<!--                          item.type === TNT_DELIVERY_SERVICE.name ||-->
<!--                          item.type === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                          item.type === USPS_DELIVERY_SERVICE.name ||-->
<!--                          item.data.delivery_method_id === APC_DELIVERY_SERVICE.deliveryMethodId">+</span>-->
<!--                      <template v-if="item.data.name === 'Post NL(paid VAT)' && (fromPoland || fromPolandToEu)">-->
<!--                        {{item.data.name.replace('(paid VAT)', '')}}-->
<!--                      </template>-->
<!--                      <template v-else>-->
<!--                        {{item.data.name}}-->
<!--                      </template>-->
<!--                    </template>-->

<!--                    <template v-else-if="item.data.message">-->
<!--                      <br>-->
<!--                      <div style="white-space: normal;" class="mt-1"-->
<!--                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                        <div class="admin-edit" @click="editTranslate(['shippingCost_dpdCalcText'])"></div>-->
<!--                        {{$t('shippingCost_dpdCalcText.localization_value.value')}}-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </div>-->
<!--                  <div class="site-table-mobile__piece custom-col custom-col&#45;&#45;50">-->
<!--                    {{$t('shippingCost_shipping.localization_value.value')}}-->
<!--                    <span>-->
<!--                     <template-->
<!--                         v-if="item.data.price"-->
<!--                     >-->
<!--                        ${{item.data.price}}-->
<!--                      </template>-->
<!--                      <template v-else>-->
<!--                        - -->
<!--                      </template>-->
<!--                  </span>-->
<!--                  </div>-->
<!--                  <div class="site-table-mobile__piece custom-col custom-col&#45;&#45;50">-->
<!--                    {{$t('shippingCost_fee.localization_value.value')}}-->
<!--                    <span>-->
<!--                   <template-->
<!--                       v-if="item.data.fee"-->
<!--                   >-->
<!--                      ${{item.data.fee}}-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                      - -->
<!--                    </template>-->
<!--                  </span>-->
<!--                  </div>-->
<!--                  <div class="site-table-mobile__piece custom-col">-->
<!--                    {{$t('shippingCost_total.localization_value.value')}}-->

<!--                    <span>-->
<!--                      <template>-->
<!--                        ${{calcTotal(item)}}-->
<!--                      </template>-->
<!--                 </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </template>-->


<!--    <template v-if="results.express.length">-->
<!--      <div class="soft-text fsz12"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['shippingCost_expressOptions'])"></div>-->
<!--        {{$t('shippingCost_expressOptions.localization_value.value')}}-->
<!--      </div>-->

<!--      <div class="fragment mb-5">-->
<!--        <table-->
<!--            class="site-table small-table "-->
<!--            v-if="!isMobileFunc()"-->
<!--        >-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th>-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_companies'])"></div>-->
<!--                {{$t('shippingCost_companies.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--            <th width="100%">-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_description'])"></div>-->
<!--                {{$t('shippingCost_description.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--            <th class="white-space-line">-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_shipping'])"></div>-->
<!--                {{$t('shippingCost_shipping.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->

<!--          <tr-->
<!--              v-for="(item, index) in results.express"-->
<!--              :key="index"-->
<!--          >-->
<!--            <td>-->
<!--              <div class="table-row">-->
<!--                <div class="table-ico table-ico&#45;&#45;large">-->
<!--                  <template v-if="item.type === DHL_DELIVERY_SERVICE.name">-->
<!--                    <img src="/img/company-icons-group/table-dhl-lg.svg" alt="img">-->
<!--                  </template>-->
<!--                  <template v-if="item.type === TNT_DELIVERY_SERVICE.name">-->
<!--                    <img src="/img/company-icons-group/table-tnt-lg.svg" alt="img">-->
<!--                  </template>-->
<!--                  <template v-if="item.type === FEDEX_DELIVERY_SERVICE.name">-->
<!--                    <img src="/img/company-icons-group/table-fedex-lg.svg" alt="img">-->
<!--                  </template>-->
<!--                  <template v-if="item.type === DPD_DELIVERY_SERVICE.name">-->
<!--                    <img src="/img/company-icons-group/table-dpd-lg.svg" alt="img">-->
<!--                  </template>-->
<!--                  <template v-if="item.type === USPS_DELIVERY_SERVICE.name">-->
<!--                    <img src="/img/company-icons-group/table-usps-lg.svg" alt="img">-->
<!--                  </template>-->
<!--                </div>-->
<!--              </div>-->
<!--            </td>-->
<!--            <td>-->
<!--              <template v-if="item.type === DHL_DELIVERY_SERVICE.name">-->
<!--                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                  <div class="admin-edit" @click="editTranslate(['shippingCost_DHLExpressToBuyer', 'shippingCost_DHLPolandExpressToBuyer'])"></div>-->
<!--                  <template v-if="item.typePrice === 'normal'">-->
<!--                    {{$t('shippingCost_DHLExpressToBuyer.localization_value.value')}}-->
<!--                  </template>-->
<!--                  <template v-if="item.typePrice === 'POLAND'">-->
<!--                    {{$t('shippingCost_DHLPolandExpressToBuyer.localization_value.value')}}-->
<!--                  </template>-->
<!--                </div>-->
<!--              </template>-->
<!--              <template v-if="item.type === TNT_DELIVERY_SERVICE.name">-->
<!--                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                  <div class="admin-edit" @click="editTranslate(['shippingCost_TNTExpressToBuyer'])"></div>-->
<!--                  {{$t('shippingCost_TNTExpressToBuyer.localization_value.value')}}-->
<!--                </div>-->
<!--              </template>-->
<!--              <template v-if="item.type === FEDEX_DELIVERY_SERVICE.name">-->
<!--                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                  <div class="admin-edit" @click="editTranslate(['shippingCost_FedexExpressToBuyer'])"></div>-->
<!--                  {{$t('shippingCost_FedexExpressToBuyer.localization_value.value')}}-->
<!--                  <template v-if="item.packaging === FEDEX_EXPRESS_FEDEX_PACK.label">-->
<!--                    <br>-->
<!--                    <div style="white-space: normal;" class="mt-1"-->
<!--                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                      <div class="admin-edit" @click="editTranslate([FEDEX_EXPRESS_FEDEX_PACK.translation])"></div>-->
<!--                      {{$t(`${FEDEX_EXPRESS_FEDEX_PACK.translation}.localization_value.value`)}}-->
<!--                    </div>-->
<!--                  </template>-->
<!--                  <template v-if="item.packaging === FEDEX_EXPRESS_FEDEX_ENVELOP.label">-->
<!--                    <br>-->
<!--                    <div style="white-space: normal;" class="mt-1"-->
<!--                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                      <div class="admin-edit" @click="editTranslate([FEDEX_EXPRESS_FEDEX_ENVELOP.translation])"></div>-->
<!--                      {{$t(`${FEDEX_EXPRESS_FEDEX_ENVELOP.translation}.localization_value.value`)}}-->
<!--                    </div>-->
<!--                  </template>-->
<!--                  <div style="font-size: 14px;" v-if="item.data && item.data.label">({{item.data.label}})</div>-->
<!--                </div>-->
<!--              </template>-->
<!--              <template v-if="item.type === DPD_DELIVERY_SERVICE.name">-->
<!--                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                  <div class="admin-edit" @click="editTranslate(['shippingCost_DPDExpressToBuyer'])"></div>-->
<!--                  {{$t('shippingCost_DPDExpressToBuyer.localization_value.value')}}-->
<!--                </div>-->
<!--              </template>-->
<!--&lt;!&ndash;              <template v-if="item.data.error">&ndash;&gt;-->
<!--&lt;!&ndash;                <span class="color-red">{{ item.data.error }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;              </template>&ndash;&gt;-->
<!--            </td>-->
<!--            <td>-->
<!--              <template-->
<!--                  v-if="item.data.price && !item.data.error"-->
<!--              >-->
<!--                ${{item.data.price}}-->

<!--                <template v-if="item.data.oversize && item.data.oversize !== '0'">-->
<!--                  (for oversize ${{item.data.oversize}})-->
<!--                </template>-->

<!--              </template>-->
<!--              <template v-if="!item.data.price && !item.data.error">-->
<!--                - -->
<!--              </template>-->
<!--              <template v-if="item.data.error">-->
<!--                <span class="color-red error-style">{{ item.data.error }}</span>-->
<!--              </template>-->
<!--            </td>-->
<!--          </tr>-->

<!--          </tbody>-->
<!--        </table>-->

<!--        <div class="site-table-mobile"-->
<!--             v-if="isMobileFunc()"-->
<!--        >-->
<!--          <div class="site-table-mobile__inner custom-row">-->
<!--            <div-->
<!--                class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sm-100"-->
<!--                v-for="(item, index) in results.express"-->
<!--                :key="index"-->
<!--            >-->
<!--              <div class="site-table-mobile__item">-->
<!--                <div class="site-table-mobile__head">-->
<!--                </div>-->
<!--                <div class="site-table-mobile__row custom-row">-->
<!--                  <div class="site-table-mobile__piece custom-col">-->
<!--                    <div class="table-ico table-ico&#45;&#45;large mb-3">-->
<!--                      <template v-if="item.type === DHL_DELIVERY_SERVICE.name">-->
<!--                        <img src="/img/company-icons-group/table-dhl-lg.svg" alt="img">-->
<!--                      </template>-->
<!--                      <template v-if="item.type === TNT_DELIVERY_SERVICE.name">-->
<!--                        <img src="/img/company-icons-group/table-tnt-lg.svg" alt="img">-->
<!--                      </template>-->
<!--                      <template v-if="item.type === FEDEX_DELIVERY_SERVICE.name">-->
<!--                        <img src="/img/company-icons-group/table-fedex-lg.svg" alt="img">-->
<!--                      </template>-->
<!--                      <template v-if="item.type === DPD_DELIVERY_SERVICE.name">-->
<!--                        <img src="/img/company-icons-group/table-dpd-lg.svg" alt="img">-->
<!--                      </template>-->
<!--                      <template v-if="item.type === USPS_DELIVERY_SERVICE.name">-->
<!--                        <img src="/img/company-icons-group/table-usps-lg.svg" alt="img">-->
<!--                      </template>-->
<!--                    </div>-->
<!--                    <span>-->
<!--                      <template v-if="item.type === DHL_DELIVERY_SERVICE.name">-->
<!--                        <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                          <div class="admin-edit" @click="editTranslate(['shippingCost_DHLExpressToBuyer', 'shippingCost_DHLPolandExpressToBuyer'])"></div>-->
<!--                          <template v-if="item.typePrice === 'normal'">-->
<!--                            {{$t('shippingCost_DHLExpressToBuyer.localization_value.value')}}-->
<!--                          </template>-->
<!--                          <template v-if="item.typePrice === 'POLAND'">-->
<!--                            {{$t('shippingCost_DHLPolandExpressToBuyer.localization_value.value')}}-->
<!--                          </template>-->
<!--                        </div>-->
<!--                      </template>-->
<!--                      <template v-if="item.type === TNT_DELIVERY_SERVICE.name">-->
<!--                        <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                          <div class="admin-edit" @click="editTranslate(['shippingCost_TNTExpressToBuyer'])"></div>-->
<!--                          {{$t('shippingCost_TNTExpressToBuyer.localization_value.value')}}-->
<!--                        </div>-->
<!--                      </template>-->
<!--                      <template v-if="item.type === FEDEX_DELIVERY_SERVICE.name">-->
<!--                        <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                          <div class="admin-edit" @click="editTranslate(['shippingCost_FedexExpressToBuyer'])"></div>-->
<!--                          {{$t('shippingCost_FedexExpressToBuyer.localization_value.value')}}-->
<!--                          <template v-if="item.packaging === FEDEX_EXPRESS_FEDEX_PACK.label">-->
<!--                            <br>-->
<!--                            <div style="white-space: normal;" class="mt-1"-->
<!--                                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                              <div class="admin-edit" @click="editTranslate([FEDEX_EXPRESS_FEDEX_PACK.translation])"></div>-->
<!--                              {{$t(`${FEDEX_EXPRESS_FEDEX_PACK.translation}.localization_value.value`)}}-->
<!--                            </div>-->
<!--                          </template>-->
<!--                          <template v-if="item.packaging === FEDEX_EXPRESS_FEDEX_ENVELOP.label">-->
<!--                            <br>-->
<!--                            <div style="white-space: normal;" class="mt-1"-->
<!--                                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                              <div class="admin-edit" @click="editTranslate([FEDEX_EXPRESS_FEDEX_ENVELOP.translation])"></div>-->
<!--                              {{$t(`${FEDEX_EXPRESS_FEDEX_ENVELOP.translation}.localization_value.value`)}}-->
<!--                            </div>-->
<!--                          </template>-->
<!--                        </div>-->
<!--                      </template>-->
<!--                      <template v-if="item.type === DPD_DELIVERY_SERVICE.name">-->
<!--                        <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                          <div class="admin-edit" @click="editTranslate(['shippingCost_DPDExpressToBuyer'])"></div>-->
<!--                          {{$t('shippingCost_DPDExpressToBuyer.localization_value.value')}}-->
<!--                        </div>-->
<!--                      </template>-->
<!--                      <template v-if="item.data.error">-->
<!--                        <span class="color-red">{{ item.data.error }}</span>-->
<!--                      </template>-->
<!--                    </span>-->
<!--                  </div>-->
<!--                  <div class="site-table-mobile__piece custom-col custom-col&#45;&#45;50">-->
<!--                    {{$t('shippingCost_shipping.localization_value.value')}}-->
<!--                    <span>-->
<!--                     <template-->
<!--                         v-if="item.data.price && !item.data.error"-->
<!--                     >-->
<!--                        ${{item.data.price}}-->

<!--                        <template v-if="item.data.oversize && item.data.oversize !== '0'">-->
<!--                          (for oversize ${{item.data.oversize}})-->
<!--                        </template>-->

<!--                      </template>-->
<!--                      <template v-if="!item.data.price && !item.data.error">-->
<!--                        - -->
<!--                      </template>-->
<!--                      <template v-if="item.data.error">-->
<!--                        <span class="color-red error-style">{{ item.data.error }}</span>-->
<!--                      </template>-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </template>-->

<!--    <template v-if="results.easypost.length">-->
<!--      <div class="soft-text fsz12"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['shippingCost_ShippingCostTitle'])"></div>-->
<!--        {{$t('shippingCost_ShippingCostTitle.localization_value.value')}}-->
<!--      </div>-->

<!--      <div class="fragment">-->
<!--        <table-->
<!--            class="site-table small-table "-->
<!--            v-if="!isMobileFunc()"-->
<!--        >-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th>-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_companies'])"></div>-->
<!--                {{$t('shippingCost_companies.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--            <th width="100%">-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_description'])"></div>-->
<!--                {{$t('shippingCost_description.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--            <th class="white-space-line">-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_shipping'])"></div>-->
<!--                {{$t('shippingCost_shipping.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--            <th class="text-lg-right white-space-line">-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_fee'])"></div>-->
<!--                {{$t('shippingCost_fee.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--            <th class="text-lg-right white-space-line">-->
<!--              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['shippingCost_total'])"></div>-->
<!--                {{$t('shippingCost_total.localization_value.value')}}-->
<!--              </div>-->
<!--            </th>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->

<!--          <tr-->
<!--              v-for="(item, index) in results.easypost"-->
<!--              :key="index"-->
<!--          >-->
<!--            <td>-->
<!--              <div class="table-row">-->
<!--                <div class="table-ico table-ico&#45;&#45;large">-->
<!--                  <template v-if="item.data.shipping_company.toLowerCase() === DHL_DELIVERY_SERVICE.name ||-->
<!--                  item.data.shipping_company.toLowerCase() === DHLEXPRESS_DELIVERY_SERVICE.name">-->
<!--                    <img src="/img/company-icons-group/table-dhl-lg.svg" alt="img">-->
<!--                  </template>-->
<!--                  <template v-if="item.data.shipping_company.toLowerCase() === TNT_DELIVERY_SERVICE.name">-->
<!--                    <img src="/img/company-icons-group/table-tnt-lg.svg" alt="img">-->
<!--                  </template>-->
<!--                  <template v-if="item.data.shipping_company.toLowerCase() === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                  item.data.shipping_company.toLowerCase() === FEDEXSMARTPOST_DELIVERY_SERVICE.name">-->
<!--                    <img src="/img/company-icons-group/table-fedex-lg.svg" alt="img">-->
<!--                  </template>-->
<!--                  <template v-if="item.data.shipping_company.toLowerCase() === DPD_DELIVERY_SERVICE.name">-->
<!--                    <img src="/img/company-icons-group/table-dpd-lg.svg" alt="img">-->
<!--                  </template>-->
<!--                  <template v-if="item.data.shipping_company.toLowerCase() === USPS_DELIVERY_SERVICE.name">-->
<!--                    <img src="/img/company-icons-group/table-usps-lg.svg" alt="img">-->
<!--                  </template>-->
<!--                  <template v-if="item.data.shipping_company.toLowerCase() === APC_DELIVERY_SERVICE.name">-->
<!--                    <img src="/img/company-icons-group/table-apc-lg.svg" alt="img">-->
<!--                  </template>-->
<!--                </div>-->
<!--              </div>-->
<!--            </td>-->
<!--            <td>-->
<!--              {{item.data.name}}-->
<!--            </td>-->
<!--            <td>-->
<!--              <template-->
<!--                  v-if="item.data.shipmentCost"-->
<!--              >-->
<!--                ${{item.data.shipmentCost}}-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                - -->
<!--              </template>-->
<!--            </td>-->
<!--            <td align="right">-->
<!--              <template-->
<!--                  v-if="item.data.fee"-->
<!--              >-->
<!--                ${{item.data.fee}}-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                - -->
<!--              </template>-->
<!--            </td>-->
<!--            <td align="right">-->
<!--              <div class="table-row">-->
<!--                ${{calcTotal(item)}}-->
<!--              </div>-->
<!--            </td>-->
<!--          </tr>-->

<!--          </tbody>-->
<!--        </table>-->

<!--        <div class="site-table-mobile"-->
<!--             v-if="isMobileFunc()"-->
<!--        >-->
<!--          <div class="site-table-mobile__inner custom-row">-->
<!--            <div-->
<!--                class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sm-100"-->
<!--                v-for="(item, index) in results.easypost"-->
<!--                :key="index"-->
<!--            >-->
<!--              <div class="site-table-mobile__item">-->
<!--                <div class="site-table-mobile__head">-->
<!--                </div>-->
<!--                <div class="site-table-mobile__row custom-row">-->
<!--                  <div class="site-table-mobile__piece custom-col">-->
<!--                    <span> {{item.data.name}} </span>-->
<!--                  </div>-->
<!--                  <div class="site-table-mobile__piece custom-col custom-col&#45;&#45;50">-->
<!--                    {{$t('shippingCost_shipping.localization_value.value')}}-->
<!--                    <span>-->
<!--                     <template-->
<!--                         v-if="item.data.shipmentCost"-->
<!--                     >-->
<!--                        ${{item.data.shipmentCost}}-->
<!--                      </template>-->
<!--                      <template v-else>-->
<!--                        - -->
<!--                      </template>-->
<!--                  </span>-->
<!--                  </div>-->
<!--                  <div class="site-table-mobile__piece custom-col custom-col&#45;&#45;50">-->
<!--                    {{$t('shippingCost_fee.localization_value.value')}}-->
<!--                    <span>-->
<!--                   <template-->
<!--                       v-if="item.data.fee"-->
<!--                   >-->
<!--                      ${{item.data.fee}}-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                      - -->
<!--                    </template>-->
<!--                  </span>-->
<!--                  </div>-->
<!--                  <div class="site-table-mobile__piece custom-col">-->
<!--                    {{$t('shippingCost_total.localization_value.value')}}-->

<!--                    <span>-->
<!--                   ${{calcTotal(item)}}-->
<!--                 </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </template>-->
  </div>
</template>

<script>
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import {
    DHL_DELIVERY_SERVICE,
    FEDEX_DELIVERY_SERVICE,
    TNT_DELIVERY_SERVICE,
    DPD_DELIVERY_SERVICE,
    USPS_DELIVERY_SERVICE,
    DHLEXPRESS_DELIVERY_SERVICE,
    APC_DELIVERY_SERVICE,
    FEDEX_EXPRESS_FEDEX_PACK,
    FEDEX_EXPRESS_FEDEX_ENVELOP,
    FEDEXSMARTPOST_DELIVERY_SERVICE,
    POSTNL_DELIVERY_SERVICE, GLOBAL24_SHIPPING_COMPANY, APC_CAMBRIDGE_DELIVERY_SERVICE, ROYAL_MAIL_DELIVERY_SERVICE,
  } from "../../../../../../../staticData/staticVariables";
  import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
  import SortButton from "@/components/UI/buttons/SortButton/SortButton";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "CalculatorResult",
    components: {
      ValueHelper,
      SortButton,
      LinkButton

    },

    mixins: [mixinDetictingMobile],

    props: {
      results: Object,
      sortParams: Object,
      calcData: Array,
      checkPayPalResult: Boolean,
      fromPolandToEu: Boolean,
      fromPoland: Boolean,
    },

    computed: {

      calcDataHasExpress() {
        return this._.find(this.calcData, {order_type: 'express'})
      },

      calcDataHasConsolidation() {
        return this._.find(this.calcData, {order_type: 'consolidation'})
      },

      getConsolidationTableItems() {
        if (this.fromPolandToEu) {
          return this.results.consolidation.filter(item => {
            return item.type !== GLOBAL24_SHIPPING_COMPANY.name.toLowerCase()
          })
        }
        else {
          return this.results.consolidation
        }
      },

      getGlobalOrderTableItems() {
        if (this.fromPolandToEu) {
          return this.results.consolidation.filter(item => {
            return item.type === GLOBAL24_SHIPPING_COMPANY.name.toLowerCase()
          })
        }
        else {
          return []
        }
      },
    },

    data(){
      return{
        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        FEDEXSMARTPOST_DELIVERY_SERVICE: FEDEXSMARTPOST_DELIVERY_SERVICE,
        TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,
        DPD_DELIVERY_SERVICE: DPD_DELIVERY_SERVICE,
        USPS_DELIVERY_SERVICE: USPS_DELIVERY_SERVICE,
        DHLEXPRESS_DELIVERY_SERVICE: DHLEXPRESS_DELIVERY_SERVICE,
        APC_DELIVERY_SERVICE: APC_DELIVERY_SERVICE,
        APC_CAMBRIDGE_DELIVERY_SERVICE: APC_CAMBRIDGE_DELIVERY_SERVICE,
        FEDEX_EXPRESS_FEDEX_PACK: FEDEX_EXPRESS_FEDEX_PACK,
        FEDEX_EXPRESS_FEDEX_ENVELOP: FEDEX_EXPRESS_FEDEX_ENVELOP,
        POSTNL_DELIVERY_SERVICE: POSTNL_DELIVERY_SERVICE,
        GLOBAL24_SHIPPING_COMPANY: GLOBAL24_SHIPPING_COMPANY,
        ROYAL_MAIL_DELIVERY_SERVICE: ROYAL_MAIL_DELIVERY_SERVICE,
      }
    },

    methods: {
      isGlobal24(item) {
        return item.type === GLOBAL24_SHIPPING_COMPANY.name.toLowerCase()
      },

      calcTotal(item) {
        return (+(item.data?.shipmentCost || item.data?.price || 0) + +(item.data?.fee || 0)).toFixed(2)
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../../../../../../scss/colors";

  .table-ico--large {
    width: 60px;
    margin: 0 auto;
  }

.table-ico--mh32 {
  max-height: 32px;

  img {
    max-height: 32px;
  }
}

  .color-red {
    color: $red;
  }
  .site-table thead th:first-child,
  .site-table tbody td:first-child{
    padding-left: 10px;
    min-width: 135px;
  }

  .site-table thead th:nth-child(2),
  .site-table tbody td:nth-child(2){
    padding-left: 0;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child {
    text-align: right;
    padding-right: 10px;
  }

.small-table td{
  white-space: initial;
}

.error-style{
  min-width: 250px;
  display: block;
}

</style>