<template>
 <div class="fragment">
   <div class="section-label"
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
     <div class="admin-edit" @click="editTranslate(['shippingCost_productInformation',])"></div>
     {{$t('shippingCost_productInformation.localization_value.value')}}
   </div>

   <div class="custom-row"
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
     <div class="admin-edit" @click="editTranslate([
         'common_weightKg',
         'consolidations_heightCm',
         'consolidations_widthCm',
         'consolidations_lengthCm',
         ])"></div>
     <div class="custom-col custom-col--25 custom-col--md-50">
       <DefaultInput
           :label="$t('common_weightKg.localization_value.value')"
           :type="'text'"
           :placeholder="'e.g. 10'"
           v-bind:class="{'ui-no-valid': calculator.data.Dimensions.validation.weight}"
           :errorTxt="$t(`${calculator.data.Dimensions.validationTranslate.weight}.localization_value.value`)"
           :error="calculator.data.Dimensions.validation.weight"
           :disabled="$store.getters.getConsolidationBtn"
           v-model="calculator.data.Dimensions.data.weight"
           @input.native="acceptNumber($event, 'weight')"
       />
     </div>
     <div class="custom-col custom-col--25 custom-col--md-50">
       <DefaultInput
           :label="$t('consolidations_heightCm.localization_value.value')"
           :type="'text'"
           :placeholder="'e.g. 10'"
           v-bind:class="{'ui-no-valid': calculator.data.Dimensions.validation.height}"
           :errorTxt="$t(`${calculator.data.Dimensions.validationTranslate.height}.localization_value.value`)"
           :error="calculator.data.Dimensions.validation.height"
           :disabled="$store.getters.getConsolidationBtn"
           v-model="calculator.data.Dimensions.data.height"
           @input.native="acceptNumber($event, 'height')"
       />
     </div>
     <div class="custom-col custom-col--25 custom-col--md-50">
       <DefaultInput
           :label="$t('consolidations_widthCm.localization_value.value')"
           :type="'text'"
           :placeholder="'e.g. 10'"
           v-bind:class="{'ui-no-valid': calculator.data.Dimensions.validation.width}"
           :errorTxt="$t(`${calculator.data.Dimensions.validationTranslate.width}.localization_value.value`)"
           :error="calculator.data.Dimensions.validation.width"
           :disabled="$store.getters.getConsolidationBtn"
           v-model="calculator.data.Dimensions.data.width"
           @input.native="acceptNumber($event, 'width')"
       />
     </div>
     <div class="custom-col custom-col--25 custom-col--md-50">
       <DefaultInput
           :label="$t('consolidations_lengthCm.localization_value.value')"
           :type="'text'"
           :placeholder="'e.g. 10'"
           v-bind:class="{'ui-no-valid': calculator.data.Dimensions.validation.dimensionLength}"
           :errorTxt="$t(`${calculator.data.Dimensions.validationTranslate.dimensionLength}.localization_value.value`)"
           :error="calculator.data.Dimensions.validation.dimensionLength"
           :disabled="$store.getters.getConsolidationBtn"
           v-model="calculator.data.Dimensions.data.dimensionLength"
           @input.native="acceptNumber($event, 'length')"
       />
     </div>

   </div>
 </div>
</template>

<script>
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "DimensionsBlock",

  components: {
    DefaultInput
  },

  props: {
    calculator: Object,
  },

  methods: {
    acceptNumber(event, type) {
      switch (type) {
        case 'weight':
          this.calculator.data.Dimensions.data.weight = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
          break
        case 'length':
          this.calculator.data.Dimensions.data.dimensionLength = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
          break
        case 'width':
          this.calculator.data.Dimensions.data.width = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
          break
        case 'height':
          this.calculator.data.Dimensions.data.height = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
          break
        default:
          break
      }
    }
  }
}
</script>

<style scoped>

</style>