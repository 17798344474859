<template>
  <div class="large-radio">

      <label class="large-radio__label"
             :for="'large-radio1-' + id"
      >
        <input class="large-radio__input"
               type="radio"
               :name="name"
               :value="value"
               :id="'large-radio1-' + id"
               @change="radioChange"
               :checked="checked"
        >
        <div class="large-radio__btn">
          <span>
            <span class="large-radio__ico">
              <CheckIco/>
            </span>
            <p v-if="htmlFix"  v-html="label"/>
            <template v-if="!htmlFix">
             {{ label }}
            </template>
          </span>
        </div>
      </label>
  </div>
</template>

<script>
  import CheckIco from "../../../../../public/img/common/check-brown-white.svg?inline"

  export default {
    name: "LargeRadio",

    components: {
      CheckIco
    },

    data () {
      return {
        id: null,
        content: this.value,
      }
    },

    props: [
      'label',
      'name',
      'checked',
      'value',
      'htmlFix',
    ],

    mounted () {
      this.id = this._uid;
    },

    methods:{
      radioChange(val){
        this.$emit('change', val.target.value);
      }
    }

  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";

  .large-radio{
    display: flex;
    border-radius: 5px;
    max-width: 280px;
    overflow: hidden;
    background: $mainBg;
    border: 1px solid $borderBrown;

    &__input{
      display: none;

      &:checked + .large-radio__btn .large-radio__ico{
        display: flex;
      }
    }

    &.soft-text{
      .large-radio__btn > span{
        color: $borderBrown;
        font-weight: 500;
        font-size: 18px;

        @include for-1600{
          font-size: 14px;
        }

      }

    }

    &__label{
      display: flex;
      cursor: pointer;
      width: 100%;
      max-width: initial;
      align-items: center;
      justify-content: center;
    }

    &__btn{
      padding: 0 5px;
      height: 42px;
      width: 100%;
      display: flex;
      align-items: center;
      max-width: initial;
      justify-content: center;
      position: relative;

      &:before{
        content: '';
        background: $brown;
        border-radius: 5px;
        /*width: calc(100% + 18px);*/
        width: calc(100% + 2px);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -1px;
        display: block;
        height: calc(100% + 1px);
        opacity: 0;
      }

      span:not(.large-radio__ico){
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #232323;
        /*position: relative;*/
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .font-size-small-14 &__btn  span:not(.large-radio__ico){
      font-size: 14px;
      line-height: normal;
    }
    .font-size-small-16 &__btn  span:not(.large-radio__ico){
      font-size: 16px;
      line-height: normal;
    }


    &__input:checked + &__btn{
      background: $brown;
      border-radius: 5px;

      &:before{
        opacity: 1;
      }

      span{
        color: white;
      }
    }


    &__ico{
      display: none;
      justify-content: center;
      align-items: center;
      padding-bottom: 1px;

      position: absolute;
      right: 4px;
      top: 4px;


      svg{
        width: 12px;
        height: 12px;
      }
    }


  }
</style>
