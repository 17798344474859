<template>
  <div class="calculator">
    <div class="calculator__inner">
      <div class="calculator__col">
        <div class="order-create__head mb-4">
          <div class="order-create__head-line">
            <div class="order-create__header-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['shippingCost_backToDashboard',])"></div>
              <router-link :to="$store.getters.GET_PATHS.dashboard" class="order-create__head-back">
                <LinkBack
                    :value="$t('shippingCost_backToDashboard.localization_value.value')"
                />
              </router-link>
            </div>
          </div>
          <div class="order-create__head-title"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['shippingCost_shippingCalculator',])"></div>
            {{$t('shippingCost_shippingCalculator.localization_value.value')}}
          </div>
          <div class="order-create__head-txt mb-0"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['shippingCost_shippingCalculatorText',])"></div>
            {{$t('shippingCost_shippingCalculatorText.localization_value.value')}}
          </div>
        </div>

        <div class="order-create__section">

          <FromWarehouseBlock
              :calculator="calculator"
              :createData="createData"
          />

          <AddressBlock
              :calculator="calculator"
              :createData="createData"
              :countries="countries"
              :countriesUK="countriesUK"
              :states="states"
          />

          <DimensionsBlock
              :calculator="calculator"
          />

<!--          <div class="custom-row">-->
<!--            <div class="custom-col"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['shippingCost_payPalClient',])"></div>-->
<!--              <DefaultCheckbox-->
<!--                  :label="$t('shippingCost_payPalClient.localization_value.value')"-->
<!--                  v-model="calculator.data.payPalClient"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

          <div class="custom-row">
            <div class="custom-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['shippingCost_shippingCalculatorText',])"></div>
              <MainButton
                  :value="$t('shippingCost_count.localization_value.value')"
                  @click.native="$emit('shippingCostCalculate')"
                  v-bind:class="{'disabled-btn' : $store.getters.getShippingCostBtn}"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="calculator__col">
<!--            v-if="results && !$store.getters.getShippingCostBtn && (results.consolidation.length || results.express.length || results.easypost.length)"-->
        <CalculatorResult
            v-if="calcData && calcData.length > 0 && !$store.getters.getShippingCostBtn"
            :results="results"
            :calcData="calcData"
            :sortParams="sortParams"
            :checkPayPalResult="checkPayPalResult"
            :fromPolandToEu="fromPolandToEu"
            :fromPoland="fromPoland"
            @changeSort="data => $emit('changeSort', data)"
        />
        <DotsShadowPreloader
            v-else-if="$store.getters.getShippingCostBtn"
        />
        <NoResult
            v-else
            :title="$t('common_noResult.localization_value.value')"
            :text="$t('common_noResultSeems.localization_value.value')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import CalculatorResult from "./CalculatorResult/CalculatorResult";
  import NoResult from "../../../../../coreComponents/NoResult/NoResult";
  import FromWarehouseBlock from "../../chunks/FromWarehouseBlock/FromWarehouseBlock";
  import AddressBlock from "../../chunks/AddressBlock/AddressBlock";
  import DimensionsBlock from "../../chunks/DimensionsBlock/DimensionsBlock";
  import DotsShadowPreloader from "../../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
  // import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

  export default {
    name: "CalculatorUser",
    components: {
      // DefaultCheckbox,
      DotsShadowPreloader,
      DimensionsBlock,
      AddressBlock,
      FromWarehouseBlock,
      LinkBack,
      // DefaultCheckbox,
      MainButton,
      CalculatorResult,
      NoResult,
    },

    props: {
      calculator: Object,
      createData: Object,
      sortParams: Object,
      countries: Array,
      countriesUK: Array,
      states: Array,
      results: [Object, Array],
      calcData: [Object, Array],
      checkPayPalResult: Boolean,
      fromPolandToEu: Boolean,
      fromPoland: Boolean,
    },

    data(){
      return{

      }
    },

    methods: {
      showResult(val){
        this.result = val
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../../../scss/mixins/mixins";
  @import "../../../../../../scss/colors";

  .calculator{
    margin-top: 30px;

    @include for-1600{
      margin-bottom: 15px;
    }

    &__inner{
      display: flex;
      flex-wrap: wrap;
    }

    &__col{
      width: 50%;
      padding: 48px;

      &:first-child{
        background: white;
        border-radius: 5px 0 0 5px;
      }

      &:last-child{
        border-radius: 0 5px 5px 0;
        border: 1px solid $borderBrown;
      }

      @include for-1600{
        padding: 25px 20px;
      }

      @include for-1200{
        width: 100%;
        padding: 25px 15px;
      }
    }

    .empty-list{
      min-height: calc(100vh - 310px);
    }

  }

</style>