<template>
  <div class="fragment">
    <div class="section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['shippingCost_destinationAddress',])"></div>
      {{$t('shippingCost_destinationAddress.localization_value.value')}}
    </div>

    <div class="custom-row"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'common_country',
          'common_city',
          'common_region',
          'common_zipCode',
          ])"></div>
      <div class="custom-col custom-col--50 custom-col--md-100">
        <div class="customs-info__search-input">
<!--              :options="calculator.getSenderWarehouseId() !== SHIPPING_COST_CALC_WAREHOUSE_GREAT_BRITAIN.id ? countries: countriesUK"-->
          <DefaultSelect
              :options="countries"
              :label="$t('common_country.localization_value.value')"
              v-bind:class="{'ui-no-valid': calculator.validation.country}"
              :errorTxt="$t(`${calculator.validationTranslate.country}.localization_value.value`)"
              :error="calculator.validation.country"
              :optionsLabel="'label'"
              :otherValue="'currentTranslation'"
              @change="changeCountry"
              :selected="calculator.data.country"
          />
        </div>
      </div>
      <div class="custom-col custom-col--50 custom-col--md-100">
        <DefaultInput
            :label="$t('common_city.localization_value.value')"
            v-bind:class="{'ui-no-valid': calculator.validation.city}"
            :errorTxt="$t(`${calculator.validationTranslate.city}.localization_value.value`)"
            :error="calculator.validation.city"
            v-model="calculator.data.city"
        />
      </div>
      <template
          v-if="calculator.getCountry().id === USA_COUNTRY_ID"
      >
        <div class="custom-col custom-col--50 custom-col--md-100">
          <DefaultSelect
              :options="states"
              :label="$t('common_region.localization_value.value')"
              v-bind:class="{'ui-no-valid': calculator.validation.countryRegion}"
              :errorTxt="$t(`${calculator.validationTranslate.countryRegion}.localization_value.value`)"
              :error="calculator.validation.countryRegion"
              :optionsLabel="'value'"
              @change="changeRegion"
              :selected="calculator.data.countryRegion"
          />
        </div>
      </template>

      <div class="custom-col custom-col--25 custom-col--md-50">
        <DefaultInput
            :label="$t('common_zipCode.localization_value.value')"
            v-bind:class="{'ui-no-valid': calculator.validation.postalCode}"
            :errorTxt="$t(`${calculator.validationTranslate.postalCode}.localization_value.value`)"
            :error="calculator.validation.postalCode"
            v-model="calculator.data.postalCode"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
import {USA_COUNTRY_ID} from "../../../../../../staticData/staticVariables";
import {SHIPPING_COST_CALC_WAREHOUSE_GREAT_BRITAIN} from "../../../../../../staticData/staticVariables";

export default {
  name: "AddressBlock",

  components: {
    DefaultInput,
    DefaultSelect
  },

  props: {
    calculator: Object,
    createData: Object,
    countries: Array,
    countriesUK: Array,
    states: Array,
  },

  data() {
    return {
      USA_COUNTRY_ID: USA_COUNTRY_ID,
      SHIPPING_COST_CALC_WAREHOUSE_GREAT_BRITAIN: SHIPPING_COST_CALC_WAREHOUSE_GREAT_BRITAIN,
    }
  },

  methods: {
    changeCountry(val) {
      console.log(val);
      this.calculator.setCountry(val)
    },

    changeRegion(val) {
      console.log(val);
      this.calculator.setCountryRegion(val)
    },
  }
}
</script>

<style scoped>

</style>