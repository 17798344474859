var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['shippingCost_from',])}}}),_vm._v(" "+_vm._s(_vm.$t('shippingCost_from.localization_value.value'))+" ")]),_c('div',{staticClass:"custom-row",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'shippingCost_ua',
        'shippingCost_us',
        'shippingCost_pl',
        'shippingCost_lv',
        'shippingCost_gb',
        ])}}}),_vm._l((_vm.getFromWarehouses()),function(item,index){return _c('div',{key:index,staticClass:"custom-col custom-col--33 custom-col--sm-50"},[(index === _vm.SHIPPING_COST_CALC_WAREHOUSE_UKRAINE.name || (index === _vm.SHIPPING_COST_CALC_WAREHOUSE_LATVIA.name && !_vm.isAdmin))?[_c('LargeRadio',{staticClass:"soft-text",attrs:{"checked":true,"label":_vm.$t(`shippingCost_${index}.localization_value.value`),"name":'type1'},on:{"change":function($event){return _vm.changeFromWarehouse(item)}}})]:[_c('LargeRadio',{staticClass:"soft-text",attrs:{"label":_vm.$t(`shippingCost_${index}.localization_value.value`),"name":'type1'},on:{"change":function($event){return _vm.changeFromWarehouse(item)}}})]],2)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }